<script>
// import qs from "qs";
// import PerfectScrollbar from "perfect-scrollbar";
// import { debounce } from "@/utils/util";
import moment from "moment";
export default {
  data() {
    return {
      key: "id", // 表格行ID
      data: [], // 表格数据
      cacheData: [], // 表格数据缓存
      page: 1, // 表格页
      pageSize: 10, // 表格每页数据
      total: 0, // 表格数据总数
      pageCount: 0, //表格页数
      selectedRows: [], // 选中行
      selectedRowKeys: [], // 选中行ID
      selectedList: [], // 临时选中
      loading: false, // 表格数据加载
      tableScrollY: "", // 表格高度
      body: {}, // 查询条件
      perfectScrollbar: "",
      scrollContainer: "",
      breadcrumbs: [],
      contentHeight: "500px",
      selectedRowList: [],
      exportTing: false,
      showTime: {
        format: "HH:mm",
        defaultValue: [moment().startOf("day"), moment().endOf("day")],
      },
      baseUnitList: [
        { id: 0, name: "瓶" },
        { id: 1, name: "盒" },
        { id: 2, name: "箱" },
        { id: 3, name: "包" },
        { id: 4, name: "片" },
        { id: 5, name: "个" },
      ],
      baseUnitArr: ["瓶", "盒", "箱", "包", "片", "个"],
    };
  },

  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.breadcrumbs = this.$route.meta.breadcrumbs || [];
  },
  activated() {
    this.breadcrumbs = this.$route.meta.breadcrumbs || [];
    // !this.unActive && this.getData && this.getData();
  },
  destroyed() {
    if (this.perfectScrollbar) {
      this.perfectScrollbar.destroy();
    }
    this.perfectScrollbar = null; // to make sure garbages are collected
  },
  mounted() {
    // if (this.$options.name != "home") {
    //   setTimeout(() => {
    //     this.resize();
    //     window.addEventListener("resize", debounce(this.resize, 300), false);
    //   });
    // }
  },
  // beforeRouteEnter (to, from, next) {
  //     // next((next)=>{
  //     //     next.getData()
  //     // })
  // },
  methods: {
    // -- table --
    // 重置 table 滚动高度

    // resizeGrid() {
    //   const $el = document.querySelector(".scroll-wrapper");
    //   if ($el) {
    //     const $contEl = $el.querySelector(".content-wrapper");
    //     const $advEl = $el.querySelector(".advanced-wrapper");
    //     const $gridEl = $el.querySelector(".grid-wrapper");
    //     if ($gridEl) {
    //       $gridEl.style.height =
    //         ($contEl.clientHeight ? $contEl.clientHeight : 0) -
    //         ($advEl.clientHeight ? $advEl.clientHeight : 0) +
    //         "px";
    //       $gridEl.style.overflow = "auto";

    //       this.perfectScrollbar = new PerfectScrollbar("#scrollContainer");
    //       this.scrollContainer = document.querySelector("#scrollContainer");
    //       this.scrollContainer.addEventListener("ps-y-reach-end", () => {
    //         if (this.page * this.pageSize < this.total) {
    //           this.page++;
    //           this.getData();
    //         }
    //       });
    //     }
    //   }
    // },
    // 页面跳转
    toPage(url, query) {
      this.$router.push({ path: url, query: query });
    },
    // -- 列表条件查询
    onSearch() {
      this.page = 1;
      this.data = [];
      this.selectedRowKeys = [];
      this.selectedList = [];
      if (this.scrollContainer) {
        this.scrollContainer.scrollTop = 0;
      }
      this.getData();
    },
    onReset() {
      this.form.resetFields();
      this.getData();
    },
    // -- 分页 --
    // 当前页变化
    onPageChange: function (page, pageSize) {
      this.page = page;
      this.pageSize = pageSize;
      this.getData();
    },
    // 每页显示数目变化
    onPageSizeChange: function (current, size) {
      this.page = 1;
      this.pageSize = size;
      this.getData();
    },
    // 选择行
    onSelectChange(selectedRowKeys, selectedRowList) {
      let arr = this.selectedRowKeys;
      let add = this.selectedList;
      let arrRowList = this.selectedRowList;
      if (selectedRowKeys.length > add.length) {
        for (let i = 0; i < selectedRowKeys.length; i++) {
          if (add.indexOf(selectedRowKeys[i]) == -1) {
            arr.push(selectedRowKeys[i]);
            arrRowList.push(selectedRowList[i]);
          }
        }
      } else {
        for (let i = 0; i < add.length; i++) {
          console.log(add[i], selectedRowKeys.indexOf(add[i]) == -1);
          if (selectedRowKeys.indexOf(add[i]) == -1) {
            var index = arr.indexOf(add[i]);
            arr.splice(index, 1);
            arrRowList.splice(index, 1);
          }
        }
      }
      this.selectedRowKeys = arr;
      this.selectedList = selectedRowKeys;
      this.selectedRowList = arrRowList;
      console.log(arr, arrRowList);
      //this.$emit("handleSeletChange", this.selectedRowKeys, arrRowList);
    },
    onSelectChangeBylist(selectedRowKeys, selectedRowList) {
      let arr = this.selectedRowKeys;
      let add = this.selectedList;
      let arrRowList = this.selectedRowList;
      if (selectedRowKeys.length > add.length) {
        for (let i = 0; i < selectedRowKeys.length; i++) {
          if (add.indexOf(selectedRowKeys[i]) == -1) {
            arr.push(selectedRowKeys[i]);
            let index = selectedRowList.findIndex((item) => {
              if (item[this.selectedRowKeyName] == selectedRowKeys[i]) {
                return true;
              }
            });
            arrRowList.push(selectedRowList[index]);
          }
        }
      } else {
        for (let i = 0; i < add.length; i++) {
          if (selectedRowKeys.indexOf(add[i]) == -1) {
            var index = arr.indexOf(add[i]);
            arr.splice(index, 1);
            arrRowList.splice(index, 1);
          }
        }
      }
      this.selectedRowKeys = arr;
      this.selectedList = selectedRowKeys;
      this.selectedRowList = arrRowList;
      console.log(arr, arrRowList);
      //this.$emit("handleSeletChange", this.selectedRowKeys, arrRowList);
    },
    // 删除
    delete(url, ids) {
      return this.$axios(url, { ids: ids }, "delete").then((res) => {
        if (+res.code === 100000) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 上传
    uploadChange(info) {
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name}文件上传成功`);
        this.getData();
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name}文件上传失败`);
      }
    },
    // 下载
    downloadExcel(url, body) {
      if (this.exportTing) {
        return;
      }
      this.exportTing = true;
      setTimeout(() => {
        this.exportTing = false;
      }, 2000);

      this.$post(url, body, { responseType: "blob" })
        .then((res) => {
          this.exportTing = false;
          let filename;
          console.log(!res.data);
          if (!res.data) {
            if (res.msg) {
              this.$message.error(res.msg);
            } else {
              this.$message.error(
                "导出没有数据哦，请确认查询条件是否正确！！！"
              );
            }

            return;
          }
          const cd = res.headers["content-disposition"];
          if (cd) {
            filename = cd.split(";")[1].split("filename=")[1];
            filename = decodeURIComponent(filename);
          }
          console.log(res);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          if ("download" in document.createElement("a")) {
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            // filename = this.getTimeDate(filename)
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          } else {
            navigator.msSaveBlob(blob, filename);
          }
        })
        .catch(() => {
          // this.$message.error("导出没有数据哦，请确认查询条件是否正确！！！");
        });
    },
    // 表格单元格编辑
    onCellChange(key, dataIndex, value) {
      const data = [...this.data];
      const target = data.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.data = data;
      }
    },
    // -- 操作 --
    // 编辑字段
    handlePropChange(val, record, prop) {
      const data = [...this.data];
      if (record) {
        record[prop] = val;
        this.data = data;
      }
    },
    // 编辑按钮
    edit(record) {
      const data = [...this.data];
      if (record) {
        record.editable = true;
        this.data = data;
      }
    },
    save(url, record) {
      const data = [...this.data];
      if (record) {
        delete record.editable;
        this.data = data;
        this.cacheData = data.map((item) => ({ ...item }));

        return this.$http.put(url, record).then(() => {
          this.$message.success("保存成功");
        });
      }
    },
    editCancel(record) {
      const data = [...this.data];
      if (record) {
        Object.assign(
          record,
          this.cacheData.find((item) => record[this.key] === item[this.key])
        );
        delete record.editable;
        this.data = data;
      }
    },
    getRowSpanCount2(data, key, target) {
      if (!Array.isArray(data)) return 1;
      data = data.map((_) => {
        let obj = {};
        for (let i = 0; i < key.length; i++) {
          obj[key[i]] = _[key[i]];
        }
        return obj;
      }); // 只取出筛选项
      let preValue = data[0];
      const res = [[preValue]]; // 放进二维数组里
      let index = 0; // 二维数组下标
      for (let i = 1; i < data.length; i++) {
        if (
          data[i][key[0]] &&
          data[i][key[0]] === preValue[key[0]] &&
          data[i][key[1]] === preValue[key[1]]
        ) {
          // 相同放进二维数组
          res[index].push(data[i]);
        } else {
          // 不相同二维数组下标后移
          index += 1;
          res[index] = [];
          res[index].push(data[i]);
          preValue = data[i];
        }
      }
      const arr = [];
      res.forEach((_) => {
        const len = _.length;
        for (let i = 0; i < len; i++) {
          arr.push(i === 0 ? len : 0);
        }
      });
      return arr[target];
    },
    getRowSpanCount(data, key, target) {
      if (!Array.isArray(data)) return 1;
      data = data.map((_) => {
        let obj = {};
        for (let i = 0; i < key.length; i++) {
          obj[key[i]] = _[key[i]];
        }
        return obj;
      }); // 只取出筛选项
      let preValue = data[0];
      const res = [[preValue]]; // 放进二维数组里
      let index = 0; // 二维数组下标
      for (let i = 1; i < data.length; i++) {
        if (
          data[i][key[0]] &&
          data[i][key[0]] === preValue[key[0]] &&
          data[i][key[1]] === preValue[key[1]]
        ) {
          // 相同放进二维数组
          res[index].push(data[i]);
        } else {
          // 不相同二维数组下标后移
          index += 1;
          res[index] = [];
          res[index].push(data[i]);
          preValue = data[i];
        }
      }
      const arr = [];
      res.forEach((_) => {
        const len = _.length;
        for (let i = 0; i < len; i++) {
          arr.push(i === 0 ? len : 0);
        }
      });
      return arr[target];
    },
  },
};
</script>
